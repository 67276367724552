import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import AniLink from "gatsby-plugin-transition-link/AniLink"

import ModalContact from '../Modal/ModalContact'
import Newsletter from '../Form/Newsletter'

const Footer = () => {

  const getYear = () => {
    return new Date().getFullYear();
  }

  const data = useStaticQuery(graphql`
    {
      logo: file(relativePath: {eq: "Global/footer-logo.svg"}) {
        publicURL
      }
      nestLogo: file(relativePath: {eq: "Global/nest logo.png"}) {
        childImageSharp {
          fixed(width: 21, height: 20, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }      
      brokerCheck: file(relativePath: {eq: "Global/broker-check.png"}) {
        childImageSharp {
          fixed(width: 122, height: 32, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)   

  return (
    <div>
      <footer className="footer">
        <div className="footer-top">
          <div className="grid-container">
            <div className="grid-x grid-margin-x grid-margin-y">
              <div className="cell small-12 medium-4 padding-right-medium">
                <AniLink fade to="/" title="Logo">
                  <img className="site-logo" src={data.logo.publicURL} alt="Rancho Private Wealth Logo" />
                </AniLink>                
                <p className="body-small text-white margin-zero opacity-80">Rancho Private Wealth is an innovative private wealth management firm based in San Diego. We help families, private businesses, and real estate investors locally and nationwide.</p>
              </div>
              <div className="cell small-12 medium-4">
                <p className="footer-title">Contact</p>
                <a href="https://goo.gl/maps/NpqZ2k8KWzp69nky7" target="_blank" rel="noopener noreferrer" className="flex align-baseline icon-left mobile-column mobile-align-center margin-bottom-small">
                  <i className="fas fa-map-marker-alt"></i>
                  <address className="body-small opacity-80">5531 Cancha de Golf Ste 201,<br className="hide-for-small-only" /> Rancho Santa Fe, CA 92091</address>
                </a>
                <a href="tel:+1-858-200-0616" className="flex align-baseline icon-left mobile-column mobile-align-center margin-bottom-small">
                  <i className="fas fa-phone"></i>
                  <span className="body-small gtm-phone-number opacity-80">(858) 200-0616</span>
                </a>
                <a href="mailto:info@ranchopw.com" className="flex align-baseline icon-left mobile-column mobile-align-center">
                  <i className="fas fa-envelope"></i>
                  <span className="body-small opacity-80">info@ranchopw.com</span>
                </a>                                
              </div>               
              <div className="cell small-12 medium-4">
                <p className="footer-title">Stay Up-To-Date</p>
                <Newsletter />
                <ul id="copyright-menu">
                  <li className="menu-item copyright">© {getYear()} - Rancho Private Wealth</li>
                  <li className="menu-item nest-logo">Powered by <a href="https://www.wisedigitalpartners.com/affordable-web-design/" rel="noopener noreferrer" target="_blank"> NEST<sup style={{fontSIze: '6px'}}>TM</sup> <Img fixed={data.nestLogo.childImageSharp.fixed} alt="nest Logo" width="21" /></a></li>
                </ul>
              </div> 								
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="grid-container">
            <p className="body-small opacity-50"><i>Rancho Private Wealth, Inc. is a branch office of DFPG Investments, LLC. Securities and Investment Advisory Services offered through DFPG Investments, LLC, a SEC registered Investment Advisor. Member <a href="https://www.finra.org/#/" target="_blank" rel="noopener noreferrer">FINRA</a> / <a href="https://www.sipc.org/" target="_blank" rel="noopener noreferrer">SIPC</a>. This is neither an offer to sell nor a solicitation of an offer to buy securities. The information on this site alone cannot, and should not be used in making investment decisions. Investors should carefully consider the investment objectives, risks, charges and expenses associated with any investment. Neither Rancho Private Wealth nor DFPG provide tax or legal advice, as such advice can only be provided by a qualified tax or legal professional, who all investors should consult prior to making any investment decision.</i></p>
            <div className="text-center">
              <p className="body-small margin-bottom-xsmall"><i>Check the background of this firm on FINRA’s <a href="https://brokercheck.finra.org/" target="_blank" rel="noopener noreferrer">BrokerCheck</a></i></p>
              <Img fixed={data.brokerCheck.childImageSharp.fixed} alt="Broker Check Logo" width="122" />
            </div>
					</div>
        </div>
      </footer>       
      <ModalContact />
    </div>  
  )
}

export default Footer
