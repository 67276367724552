import React, { useState, useRef, useEffect } from 'react'
import { useStaticQuery, graphql } from "gatsby"
import { useOnClickOutside } from '../../hooks'
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { AnchorLink } from "gatsby-plugin-anchor-links";

import Burger from '../Burger/Burger'
import OffCanvas from '../OffCanvas/OffCanvas'
import ButtonSolid from '../Button/ButtonSolid'

const Header = () => {

  // determine if offcanvas is open
  const [open, setOpen] = useState(false);
  const node = useRef(); 
  useOnClickOutside(node, () => setOpen(false));

  // determine if page has scrolled and if the view is on mobile
  const [scrolled, setScrolled] = useState(false);

  // change state on scroll
  useEffect(() => {
    const siteNavigation = document.querySelector('#site-navigation');
    const utilityNavigation = document.querySelector('#utlity-navigation');
    const mainNavigation = document.querySelector('#main-navigation');
    const mobileNavigation = document.querySelector('#mobile-menu');
    const bodyContent = document.querySelector('#body-content');

    // set initial mobile menu offset top
    mobileNavigation.style.top = siteNavigation.offsetHeight + 'px';

    const handleResize = () => {

      // recalculate mobile menu offset top on resize
      mobileNavigation.style.top = mainNavigation.offsetHeight + 'px';
    };    
    
    const handleScroll = () => {
      const isScrolled = window.scrollY > utilityNavigation.offsetHeight;
      if (isScrolled) {
        if(window.innerWidth < 768) {
          bodyContent.style.marginTop = mainNavigation.offsetHeight + 'px';
        } else {
          bodyContent.style.marginTop = mainNavigation.offsetHeight + 'px';
        }
        setScrolled(true);
        mobileNavigation.style.top = mainNavigation.offsetHeight + 'px';
      } else {
        setScrolled(false);
        bodyContent.style.marginTop = '';
      }
    };

    document.addEventListener('scroll', handleScroll, { passive: true });
    window.addEventListener('resize', handleResize, { passive: true });

    return () => {
      document.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, [scrolled]);

  const data = useStaticQuery(graphql`
    {
      logo: file(relativePath: {eq: "Global/header-logo.svg"}) {
        publicURL
      }
    }
  `)  

  return (
    <div id="site-navigation">
      <div id="utlity-navigation" className="hide-for-small-only">
        <div className="grid-container">
          <ul>
            <li><a className="gtm-phone-number" href="tel:+1-858-200-0616"><i className="fas fa-phone"></i>(858) 200-0616</a></li>
            <li><a href="https://wealth.emaplan.com/ema/SignIn?ema%2ffirstallied%2fpresidio" target="_blank" rel="noopener noreferrer"><i className="fas fa-user"></i>Client Login</a></li>
          </ul>
        </div>
      </div>
      <nav id="main-navigation" role="navigation" aria-label="main-navigation" data-fixed={scrolled}>
        <div className="grid-container">
          <div className="navigation-left">
            <AniLink fade to="/">
              <img className="site-logo" src={data.logo.publicURL} alt="Rancho Private Wealth Logo" />
            </AniLink>
          </div>
          <div className="navigation-right">
            <ul id="navigation-desktop" className="hide-for-small-only">
              <li className="navigation-item">
                <AnchorLink to="/#strategies" title="Strategies" />
              </li>
              <li className="navigation-item">
                <AnchorLink to="/#about" title="About" />
              </li>
              <li className="navigation-item">
                <AnchorLink to="/#philosophy" title="Philosophy" />
              </li>
              <li className="navigation-item">
                <AnchorLink to="/#team" title="Team" />    
              </li>
              <li className="navigation-item">
                <AnchorLink to="/#solutions" title="Solutions" />
              </li>
            </ul>
            <ButtonSolid as="button" className="navigation-button hide-for-small-only" data="modal-contact" text="Contact Us"/>

            <div className="show-for-small-only" ref={node}>
              <Burger open={open} setOpen={setOpen} aria-controls="mobile-menu"/>
              <OffCanvas open={open} setOpen={setOpen} id="mobile-menu">
                <ul id="navigation-mobile">
                  <li className="navigation-item" onClick={() => { setOpen(!open); }} onKeyDown={() => { setOpen(!open); }}>
                    <AnchorLink to="/#strategies" title="Strategies" />
                  </li>
                  <li className="navigation-item" onClick={() => { setOpen(!open); }} onKeyDown={() => { setOpen(!open); }}>
                    <AnchorLink to="/#about" title="About" />
                  </li>                                 
                  <li className="navigation-item" onClick={() => { setOpen(!open); }} onKeyDown={() => { setOpen(!open); }}>
                    <AnchorLink to="/#philosophy" title="Philosophy" />
                  </li>
                  <li className="navigation-item" onClick={() => { setOpen(!open); }} onKeyDown={() => { setOpen(!open); }}>
                    <AnchorLink to="/#team" title="Team" />
                  </li>                  
                  <li className="navigation-item" onClick={() => { setOpen(!open); }} onKeyDown={() => { setOpen(!open); }}>
                    <AnchorLink to="/#solutions" title="Solutions" />
                  </li>
                </ul>

                <div className="margin-bottom-large">
                  <div className="conversion-links-item">
                    <i className="fas fa-user"></i> 
                    <a href="https://wealth.emaplan.com/ema/SignIn?ema%2ffirstallied%2fpresidio" target="_blank" rel="noopener noreferrer">Client Login</a>
                  </div>                

                  <div className="conversion-links-item">
                    <i className="fas fa-phone"></i>
                    <a className="gtm-phone-number" href="tel:+1-858-200-0616">(858) 200-0616</a>
                  </div>
                </div>
                
                <ButtonSolid as="button" className="button-full" data="modal-contact" text="Schedule Consultation"/>
              </OffCanvas>
            </div>
          </div>
        </div>
      </nav>
    </div>
  )
  
}

export default Header
