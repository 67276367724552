import React from 'react'
import styled from 'styled-components'

import ButtonSolid from '../Button/ButtonSolid';
import variables from '../../sass/variables.module.scss'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const StyledForm = styled.div`
  form {
    display: flex;
    flex-wrap: wrap;
  }
  .field {
    margin-bottom: 16px;
    flex-basis: 100%;
    @media(min-width: 768px){
      &.is-half {
        flex-basis: 50%;
      }      
      &.is-left {
        padding-right: 12px;
      }
      &.is-right {
        padding-left: 12px;
      }      
    }
  }
  .select-wrapper {
    position: relative;
    &:after {
      content: "\f078";
      font-family: "Font Awesome 5 Pro";
      font-weight: 400;
      position: absolute;
      right: 25px;
      top: 50%;
      transform: translateY(-50%);
      color: ${variables.colorBlack};    
    }
  }
  label {
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 10px;
    display: block;
    color: ${variables.colorBlack};
  }
  input,
  select,
  textarea {
    width: 100%;
    padding: 15px 16px;
    font-size: 16px;
    border-radius: 2px;
    border: none;
    background-color: ${variables.colorGray100};
  }
  select {
    height: 48px;
    -webkit-appearance: inherit;
    cursor: pointer;
    option:disabled {
      color: ${variables.colorGray400};
    }
  }
  button {
    margin-top: 10px;
  }
`

export default class Form extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isValidated: false }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
    .then(() =>
      document.getElementById('contact-form-ajax-response').innerHTML = "Thanks for contacting us! We will get in touch with you shortly.",
      form.remove(),
      window.dataLayer = window.dataLayer || [],
      window.dataLayer.push({
        'event': 'contactFormSubmission'
      })  
    )
    .catch(error => alert(error))  

  }
  
  render() {
    return (
      <StyledForm>
        <div id="contact-form-ajax-response"></div>
        <form
        name="Contact"
        method="post"
        action=""
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={this.handleSubmit}
        >
        {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
        <input type="hidden" name="form-name" value="Contact" />
        <div hidden>
            <label>
            Don’t fill this out:{' '}
            <input name="bot-field" onBlur={this.handleChange} />
            </label>
        </div>

        {/* <div className="control">
          <input
              className="input"
              type={'hidden'}
              name={'subject'}
              onBlur={this.handleChange}
              id={'subject'}
              required={true}
              value="DC Plumbing - Contact Form Submission"
          />
        </div> */}

        <div className="field">
            <label className="label" htmlFor={'name'}>Full Name</label>
            <div className="control">
            <input
                className="input"
                type={'text'}
                name={'name'}
                onBlur={this.handleChange}
                id={'name'}
                required={true}
            />
            </div>
        </div>
        <div className="field">
            <label className="label" htmlFor={'phone'}>Phone Number</label>
            <div className="control">
            <input
                className="input"
                type={'tel'}
                name={'phone'}
                onBlur={this.handleChange}
                id={'phone'}
                required={true}
            />
            </div>
        </div>         
        <div className="field">
            <label className="label" htmlFor={'email'}>Email</label>
            <div className="control">
            <input
                className="input"
                type={'email'}
                name={'email'}
                onBlur={this.handleChange}
                id={'email'}
                required={true}
            />
            </div>
        </div>
        <div className="field">
            <label className="label" htmlFor={'message'}>Message</label>
            <div className="control">
            <textarea
                className="textarea"
                name={'message'}
                onChange={this.handleChange}
                id={'message'}
                rows="6"
                placeholder={"Enter your message here."}
                required={true}
            />
            </div>
        </div>
        <div className="field">
          <ButtonSolid as="button" type="submit" text="Contact Us"/>
        </div>
        </form>
      </StyledForm>
    )
  }
}