import React from 'react'
import styled from 'styled-components'
import { bool, func } from 'prop-types';

import variables from '../../sass/variables.module.scss'

const StyledBurger = styled.button`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 24px;
    height: 14px;
    background: transparent;
    border: none;
    cursor: pointer;
    &:focus {
        outline: none;
    }
    .line {
        position: relative;
        width: 24px;
        height: 1px;
        background-color: ${({ open }) => open ? variables.colorGray900 : variables.colorGray900};
        transform-origin: 1px;
        z-index: 11;
        transition: all 0.3s linear;
        &:first-child {
            transform: ${({ open }) => open ? 'rotate(45deg)' : 'rotate(0)'};
        }
        &:nth-child(2) {
            display: none;
            /* opacity: ${({ open }) => open ? '0' : '1'};
            transform: ${({ open }) => open ? 'translateX(20px)' : 'translateX(0)'}; */
        }
        &:nth-child(3) {
            margin-left: auto;
            width: ${({ open }) => open ? '24px' : '17px'};
            transform: ${({ open }) => open ? 'translateY(8px) rotate(-45deg)' : 'rotate(0)'};
        }
    }
`

const Burger = ({ open, setOpen, ...props }) => {

    const isExpanded = open ? true : false;

    return (
        <StyledBurger aria-label="Toggle menu" aria-expanded={isExpanded} open={open} onClick={() => setOpen(!open)} {...props}>
            <div className="line" />
            <div className="line" />
            <div className="line" />
        </StyledBurger>
    )
}

Burger.propTypes = {
    open: bool.isRequired,
    setOpen: func.isRequired,
};

export default Burger