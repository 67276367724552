import PropTypes from "prop-types";
import React from "react";
import Helmet from "react-helmet";

import Header from './Header/Header'
import Footer from './Footer/Footer'

function Layout({ children }) {
  return (
    <div>
      <Helmet>
        <meta name="google-site-verification" content="8bMYkUBbrSpnVrV3IfdPa9bkxiiksrOtCePgWQ_LkhY" />
      </Helmet>
      <Header />
      <main id="body-content">
        {children}
      </main>
      <Footer />
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;