import React from 'react'
import styled from 'styled-components'
import { bool } from 'prop-types';

import variables from '../../sass/variables.module.scss'

const StyledOffCanvas = styled.nav`
    background: ${variables.colorGray900};
    width: 100%;
    height: 100vh;
    padding: 55px 16px;
    position: fixed;
    left: 0;
    z-index: 10;
    transform: ${({ open }) => open ? 'translateX(0%)' : 'translateX(-100%)'};
    transition: transform 0.4s ease-in-out;
    .navigation-item {
        margin-bottom: 30px;
        a {
            font-size: 24px;
            color: ${variables.colorWhite};
            &:hover, 
            &:active,
            &:focus {
                color: ${variables.colorPrimary};
            }
        }        
    }
    .conversion-links-item {
        display: flex;
        align-items: center;
        &:not(:last-child){
            margin-bottom: 14px;
        }
        i {
            width: 33px;
            height: 33px;
            margin-right: 5px;
            border-radius: 50%;
            border: 1px solid ${variables.colorPrimary};
            display: flex;
            align-items: center;
            justify-content: center;
            color: ${variables.colorWhite};
        }
        a {
            color: ${variables.colorWhite};
            &:hover, 
            &:active,
            &:focus {
                color: ${variables.colorPrimary};
            }
        }
    }
`

const OffCanvas = ({ children, open, ...props }) => {
    
    const isHidden = open ? true : false;

    if (typeof window !== `undefined`) {
        if(open){
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }
    }

    return(
        <StyledOffCanvas open={open} aria-hidden={!isHidden} {...props}>
            {children}
        </StyledOffCanvas>
    )
}


StyledOffCanvas.propTypes = {
    open: bool.isRequired,
}

export default OffCanvas
  
  