import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'
import MicroModal from 'micromodal'

import Contact from '../Form/Contact'
import variables from '../../sass/variables.module.scss'

const StyledModal = styled.div`
    .grid-x {
        width: 100%;
    }
    .map {
        height: 100%;
    }
    .conversion-links-item {
        color: ${variables.colorBlack};
        a {
            color: ${variables.colorBlack};
            font-weight: 600;
            letter-spacing: 0.3px;
            &:hover, 
            &:active,
            &:focus {
                color: ${variables.colorPrimary};
            }            
        }
    }
`

const Modal = () => {

    if (typeof window !== `undefined`) {
        MicroModal.init({
            openTrigger: 'data-modal-open',
            closeTrigger: 'data-modal-close',
            disableFocus: true,
            disableScroll: true,
            awaitOpenAnimation: true,
            awaitCloseAnimation: true,
        })
    }

    const data = useStaticQuery(graphql`
        {
        closeIcon: file(relativePath: {eq: "Global/X.svg"}) {
            publicURL
        }        
        mapDesktop: file(relativePath: {eq: "Global/Map.jpg"}) {
            childImageSharp {
                fluid(maxWidth: 1280, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
                }
            }
        }    
    }
  `)    
  
    return (
        <StyledModal>
        <div className="modal" id="modal-contact" aria-hidden="false"> 
            <div className="overlay" tabIndex="-1" data-modal-close>
                <div className="grid-x">
                    <div className="cell small-12 medium-6">
                        <BackgroundImage className="map" fluid={data.mapDesktop.childImageSharp.fluid}></BackgroundImage>
                    </div>
                    <div className="cell small-12 medium-6">
                        <div className="content-wrapper" role="dialog" aria-modal="true">
                            <div className="content">
                                <header className="form-wrapper"> 
                                    <p className="heading-three">Schedule Your Free Consultation</p> 
                                    <p>Your future success depends on today’s plans. Schedule a free consultation to see how we can help.</p>                       
                                </header>
                                <div className="flex align-baseline icon-left conversion-links-item margin-bottom-large">
                                    <a className="gtm-phone-number" href="tel:+1-858- 200-0616">
                                        <i className="fas fa-phone"></i>
                                        <span>(858) 200-0616</span> 
                                    </a>
                                </div>                             
                                <Contact />
                                <img className="close" aria-label="Close modal" data-modal-close src={data.closeIcon.publicURL} alt="Close icon" />
                            </div>                                
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </StyledModal>
    )
}
  
export default Modal