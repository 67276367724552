import React from 'react'
import styled from 'styled-components'

import variables from '../../sass/variables.module.scss'

const Button = styled.a`
    display: inline-block;
    padding: 12px 24px;
    text-align: center; 
    font-family: ${variables.fontSecondary};
    font-size: ${variables.bodyRegular};
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: 0.3px;
    color: ${variables.colorWhite};
    background-color: ${variables.colorPrimary};
    min-height: 48px;
    min-width: 135px;
    border: none;
    border-radius: 2px;
    cursor: pointer;
    outline: none;
    transition: .3s ease;
    &:hover, 
    &:active,
    &:focus {
        color: ${variables.colorWhite};
        background-color: ${variables.colorPrimaryDark};
    }
`

export default props => <Button as={props.as} type={props.type} className={props.className} href={props.href} data-modal-open={props.data}>{props.text}</Button>